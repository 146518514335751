import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import { COUNTRY } from '../../configs/env';
import { PageContext } from '../../types/types';
import { css } from '../../helpers/css.theme';
import { theme } from '../../layouts/main.layout';

interface WpPageNodeI {
  node: {
    id: string;
    slug: string;
    date: string;
    title: string;
    content: string;
  };
}

interface TermsPageDataPropsI {
  data: {
    allWordpressPage: {
      edges: Array<WpPageNodeI>;
    };
  };
  pageContext: PageContext;
}

const mobileTermsPageStandaloneCss = css`
  width: 90vw;
  margin-left: auto;
  margin-right: auto;

  p,
  ul,
  li {
    font-size: 16px;
    line-height: 1.3;

    a {
      font-size: 16px;
    }
  }

  p {
    margin: 20px 0;
  }

  a {
    font-weight: bold;
    color: ${theme.colors.black};
    text-decoration: underline;
    position: relative;
  }

  h1,
  h2 {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  h3,
  h4,
  h5 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }
`;

export default (props: TermsPageDataPropsI): React.FC | ReactElement => {
  const lang = props.pageContext.lang;
  const generalTermsRS = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `uslovi-koriscenja-sr-rs`);
  const generalTermsRO = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `ro-terms-of-use-10-2022`);
  const generalTermsBG = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `bg-terms-of-use`);
  const generalTerms = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `uslovi-koriscenja-en-24-10-2022`);

  if (generalTermsRS && ((COUNTRY === 'rs' && lang === 'sr') || (COUNTRY === 'me' && lang === 'me'))) {
    return <div css={mobileTermsPageStandaloneCss} dangerouslySetInnerHTML={{ __html: generalTermsRS.node.content }} />;
  } else if (generalTermsRO && COUNTRY === 'ro') {
    return <div css={mobileTermsPageStandaloneCss} dangerouslySetInnerHTML={{ __html: generalTermsRO.node.content }} />;
  } else if (generalTermsBG && COUNTRY === 'bg') {
    return <div css={mobileTermsPageStandaloneCss} dangerouslySetInnerHTML={{ __html: generalTermsBG.node.content }} />;
  } else if (generalTerms) {
    return <div css={mobileTermsPageStandaloneCss} dangerouslySetInnerHTML={{ __html: generalTerms.node.content }} />;
  } else {
    return <p>Ups, something went wrong</p>;
  }
};

export const allPostsQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          title
          slug
          date
          content
          id
        }
      }
    }
  }
`;
